import { WidgetResponse } from '@bookingkit-private/api-v4';
import { ThemeType, WidgetType } from '@/services/WidgetService/types';
import { ThemeResponse } from '@bookingkit-private/api-v4/models/theme-response';

export const fromApiWidgetToWidgetType = (widget: WidgetResponse): WidgetType => {
    if (!widget.widget_id) {
        throw new Error('Widget ID is missing');
    }
    if (!widget.title) {
        throw new Error('Widget title is missing');
    }
    return {
        id: widget.widget_id,
        title: widget.title,
        description: widget.description,
        configuration: widget.config,
    };
};

export const fromApiThemeToThemeType = (theme: ThemeResponse): ThemeType => {
    if (!theme.id) {
        throw new Error('Theme ID is missing');
    }
    if (!theme.name) {
        throw new Error('Theme name is missing');
    }
    if (!theme.colours) {
        throw new Error('Theme colours are missing');
    }
    if (!theme.colours.light) {
        throw new Error('Theme light colours are missing');
    }
    if (!theme.colours.dark) {
        throw new Error('Theme dark colours are missing');
    }
    return {
        id: theme.id,
        name: theme.name,
        colours: {
            light: {
                primary: theme.colours.light.colour as string,
            },
            dark: {
                primary: theme.colours.dark.colour as string,
            },
        },
    };
};

export default {
    fromApiWidgetToWidgetType,
};
