{
  "alerts": {
    "companyInvoicePaymentDetails": {
      "actions": {
        "cancel": "Schließen",
        "confirm": "Rechnungsdetails aktualisieren"
      },
      "description": "Wir konnten die letzte Rechnung nicht abbuchen, weil die angegebenen Rechnungsdaten falsch oder unvollständig sind. Bitte stelle sicher, dass deine Zahlungsinformation auf dem neuesten Stand sind, um Unterbrechungen zu vermeiden. Du benötigst eine gültige Kredit-/Debitkarte oder ein gültiges Bankkonto, um fortzufahren.",
      "heading": "Rechnungsdetails aktualisieren"
    }
  },
  "c": "BETA",
  "calendar": {
    "addAppointment": "Termin hinzufügen",
    "downloadChecklist": "Checkliste",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Keine übereinstimmenden Erlebnisse",
        "noExperiences": "Keine übereinstimmenden Erlebnisse",
        "noInactiveExperienceMatchingFilter": "Keine übereinstimmenden inaktiven Erlebnisse"
      }
    },
    "warnings": {
      "noExperiences": "Keine übereinstimmenden Erlebnisse"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Schnelle Maßnahmen"
        }
      },
      "navAccount": {
        "help": {
          "label": "Hilfe"
        },
        "switch": {
          "label": "Wechseln zu"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "Tag | Tage",
    "hours": "Stunde | Stunden",
    "minutes": "Min."
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Zur Übersicht gehen",
        "description": "Ihr Konto verfügt nicht über die erforderlichen Rechte, um diese Seite anzuzeigen.",
        "title": "Sie sind nicht berechtigt, diese Seite anzuzeigen"
      },
      "serverError": {
        "cta": "Versuchen Sie es erneut",
        "description": "Aufgrund eines internen Serverfehlers konnten wir Ihre Anfrage nicht durchführen. Wir wurden darüber in Kenntnis gesetzt. Versuchen Sie es bitte später erneut.",
        "title": "Hoppla, da ist ein grober Fehler aufgetreten"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Der Anbieter hat keine Wallets aktiviert"
      }
    },
    "validationErrors": {
      "invalidIban": "Ungültige IBAN",
      "notAValidEmail": "Das Feld ist keine gültige E-Mail-Adresse",
      "notAValidMoneyAmount": "Ungültiger Geldbetrag",
      "notEmpty": "Das Feld darf nicht leer sein",
      "notIdenticalIban": "Bestätigen, dass die IBAN mit folgender IBAN übereinstimmen muss",
      "unrecognisedDateTime": "Datum und Uhrzeit wurden nicht erkannt"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Wählen Sie aus, welchem Erlebnis Sie eine neue Verfügbarkeit zuweisen möchten.",
      "title": "Dem Erlebnis einen Termin hinzufügen"
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Aktualisieren",
      "title": "Es steht eine neue Version von bookingkit bereit"
    }
  },
  "pagination": {
    "next": "Vor",
    "previous": "Zurück"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Termin hinzufügen",
    "disableHere": "Tastaturbefehle hier deaktivieren",
    "enableShortcuts": "Tastaturbefehle aktivieren",
    "keyboardShortcuts": "Tastenkombinationen",
    "ok": "Ok",
    "powerShortcuts": "Schnellwahl-Tastenkombinationen",
    "search": "Suchen",
    "searchShortcutToastInfoMac": "⌘ + F nochmals drücken, um die native Browsersuche zu aktivieren",
    "searchShortcutToastInfoWindows": "Ctrl + F nochmals drücken, um die native Browsersuche zu aktivieren",
    "toastMessage": "Tastaturbefehle werden standardmäßig aktiviert.",
    "vendorShortcutToastInfoMac": "⌘ + M nochmals drücken, um den nativen Browser-Tastaturbefehl zu aktivieren",
    "vendorShortcutToastInfoWindows": "Ctrl + M nochmals drücken, um den nativen Browser-Tastaturbefehl zu aktivieren",
    "vendorSwitch": "Umstellung auf anderen Anbieter"
  },
  "search": {
    "accepted": "AKZEPTIERT",
    "archived": "ARCHIVIERT",
    "canceled": "STORNIERT",
    "coupon": "Coupon",
    "declined": "ABGELEHNT",
    "expired": "ABGELAUFEN",
    "failure": "FEHLGESCHLAGEN",
    "feature": "Funktion",
    "flexibleTicket": "Flexibles Ticket",
    "invalid": "Ungültig",
    "noResultFound": "Kein Ergebnis gefunden",
    "noResultFoundMessage": "Deine Suche stimmt mit keinem Ergebnis überein. Bitte versuche es auf eine andere Weise.",
    "open": "ÖFFNEN",
    "order": "Bestellung",
    "pending": "AUSSTEHEND",
    "reserved": "RESERVIERT",
    "search": "Suchen",
    "searchEntryMessage": "Du kannst nach Folgendem suchen:",
    "searchError": "Da ist etwas schiefgelaufen.",
    "searchErrorMessage": "Wir arbeiten daran, das Problem zu beheben. Bitte versuche es in Kürze erneut.",
    "searchFeatureEntryMessage": "Funktionen nach Namen.",
    "searchOrderEntryMessage": "Bestellungen nach IDs, nach Kunden- oder sonstigen auftragsbezogenen Daten;",
    "searchScopes": {
      "all": "Alle",
      "coupons": "Coupons",
      "features": "Funktionen",
      "flexibleTickets": "Flexible Tickets",
      "orders": "Bestellungen",
      "vouchers": "Gutscheine"
    },
    "searchVoucherEntryMesaage": "Gutscheine nach Code, Titel oder Beschreibung;",
    "searchVoucherEntryMessage": "Gutscheine nach Code, Titel oder Beschreibung;",
    "ticketTypes": {
      "all": "Alle",
      "coupons": "Coupons",
      "features": "Funktionen",
      "flexibleTickets": "Flexible Tickets",
      "orders": "Bestellungen",
      "vouchers": "Gutscheine"
    },
    "valid": "Gültig",
    "voucher": "Gutschein"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Firmenangaben"
      },
      "contract": {
        "label": "Vertrag"
      },
      "main": {
        "label": "Konto"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Abmelden"
      },
      "userManagement": {
        "label": "Benutzermanagement"
      },
      "vendorSwitcher": {
        "label": "Anbieter-Umsteller"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "API-Zahlungen"
      },
      "applicaitonLimits": {
        "label": "Anwendungsbeschränkungen"
      },
      "application": {
        "label": "Anwendung"
      },
      "applicationLimits": {
        "label": "Anwendungsbeschränkungen"
      },
      "assessments": {
        "label": "Veranlagungen"
      },
      "changeRates": {
        "label": "Tarife ändern"
      },
      "chargeVouchers": {
        "label": "Gutscheine aufladen"
      },
      "claimAccount": {
        "label": "Konto zuweisen"
      },
      "createAccount": {
        "label": "Konto erstellen"
      },
      "dashboard": {
        "label": "Dashboard"
      },
      "disableEventDates": {
        "label": "Ereignisdaten deaktivieren"
      },
      "emailConfiguration": {
        "label": "E-Mail-Konfiguration"
      },
      "importCoupons": {
        "label": "Coupons importieren"
      },
      "importOrders": {
        "label": "Bestellungen importieren"
      },
      "importVouchers": {
        "label": "Gutscheine importieren"
      },
      "inviteNew": {
        "label": "Laden Sie einen neuen Reseller ein"
      },
      "invoices": {
        "label": "Rechnungen"
      },
      "KYCUnverified": {
        "label": "KYC nicht verifiziert"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "API verwalten"
      },
      "manageBkUsers": {
        "label": "bk-Nutzer verwalten"
      },
      "mangopayDisputes": {
        "label": "Mangopay Streitfälle"
      },
      "marketingPartners": {
        "label": "Marketingpartner"
      },
      "outstandingPayments": {
        "label": "Ausstehende Zahlungen"
      },
      "paymentRequests": {
        "label": "Zahlungsaufforderungen"
      },
      "payoutChannels": {
        "label": "Auszahlungskanäle"
      },
      "paypalPaymentRequests": {
        "label": "Paypal Zahlungsaufforderungen"
      },
      "platforms": {
        "label": "Plattformen"
      },
      "provider": {
        "label": "Anbieter"
      },
      "rates": {
        "label": "Tarife"
      },
      "resellerPaymentStatus": {
        "label": "Zahlungsstatus Händler"
      },
      "setupFee": {
        "label": "Einrichtungsgebühr"
      },
      "translationTool": {
        "label": "Übersetzungstool"
      },
      "unknownPaymentrequests": {
        "label": "Unbekannte Zahlungsaufforderungen"
      },
      "vendor_performance": {
        "label": "Leistung des Anbieters"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Tagesansicht"
      },
      "feed": {
        "label": "Kalenderübertragung"
      },
      "main": {
        "label": "Kalender"
      },
      "month": {
        "label": "Monatsansicht"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Pakete"
      },
      "experiences": {
        "label": "Erlebnisse"
      },
      "flexibleOffers": {
        "label": "Flexible Angebote"
      },
      "main": {
        "label": "Inventar"
      },
      "products": {
        "label": "Produkte"
      },
      "resources": {
        "label": "Ressourcen"
      }
    },
    "marketplace": {
      "main": {
        "label": "App-Marktplatz"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Flexible Tickets"
      },
      "main": {
        "label": "Bestellungen"
      },
      "orders": {
        "label": "Bestellungen"
      },
      "participants": {
        "label": "Teilnehmer"
      },
      "promotionalCoupons": {
        "label": "Aktionsgutscheine"
      },
      "requests": {
        "label": "Anfragen"
      },
      "vouchers": {
        "label": "Gutscheine"
      }
    },
    "overview": {
      "main": {
        "label": "Übersicht"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Affiliate-Links"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Laden Sie einen neuen Reseller ein"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Meine Partner"
      },
      "partnerRates": {
        "label": "Partner-Tarife"
      },
      "platforms": {
        "label": "Plattformen"
      },
      "promote": {
        "label": "Lokale Erlebnisse fördern"
      },
      "reachOrders": {
        "label": "Bestellungen"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Statistiken zusammenführen"
      },
      "exports": {
        "label": "Exporte"
      },
      "main": {
        "label": "Berichte"
      },
      "monthlyStatement": {
        "label": "Monatsabschluss"
      },
      "statements": {
        "label": "Abrechnungen"
      },
      "statistics": {
        "label": "Statistiken"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Wiederverkäufer"
      },
      "marketplace": {
        "label": "Marktplatz"
      },
      "myPartners": {
        "label": "Meine Partner"
      },
      "orders": {
        "label": "Bestellungen"
      },
      "platforms": {
        "label": "Integrationen von Wiederverkäufern"
      },
      "promote": {
        "label": "Lokale Erlebnisse fördern"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Checkout-Konfiguration"
      },
      "emailCampaigns": {
        "label": "E-Mail-Kampagnen"
      },
      "localExperiences": {
        "label": "Lokale Erlebnisse"
      },
      "main": {
        "label": "Verkaufen"
      },
      "newWidgets": {
        "label": "Neue Widgets"
      },
      "paymentOptions": {
        "label": "Zahlungsmöglichkeiten"
      },
      "paymentProviders": {
        "label": "Zahlungsanbieter"
      },
      "widgets": {
        "label": "Widgets"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kalenderübertragung"
      },
      "emailConfiguration": {
        "label": "E-Mail-Konfiguration"
      },
      "invoiceLayout": {
        "label": "Rechnungslayout"
      },
      "invoiceNNumber": {
        "label": "Rechnungsnummer"
      },
      "invoiceNumber": {
        "label": "Rechnungsnummer"
      },
      "main": {
        "label": "Einstellungen"
      },
      "modules": {
        "label": "Module"
      },
      "ticketConfiguration": {
        "label": "Ticket-Konfiguration"
      },
      "translations": {
        "label": "Übersetzungen"
      },
      "vat": {
        "label": "Mwst."
      },
      "voucherConfiguration": {
        "label": "Gutschein-Konfiguration"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "App-Marktplatz"
      },
      "appointment": {
        "label": "Termin hinzufügen"
      },
      "booking": {
        "label": "Buchung"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Support kontaktieren"
      },
      "copyToClipboard": {
        "failure": "Fehler beim Kopieren des Links für den Anbieter",
        "success": "Spezifischer Link für den Lieferanten kopiert:"
      },
      "date": {
        "label": "Datum"
      },
      "experience": {
        "label": "Erlebnis"
      },
      "knowledgeBase": {
        "label": "Wissensdatenbank",
        "url": "https://help.bookingkit.com/de/"
      },
      "onboardingWizard": {
        "label": "Onboarding-Assistent (zu klären)"
      },
      "powerShortcuts": {
        "label": "Schnellwahl-Tastenkombinationen"
      }
    },
    "updates": {
      "main": {
        "label": "Produkt-Updates"
      },
      "mainBadge": {
        "label": "Neu"
      }
    },
    "wallet": {
      "main": {
        "label": "Wallet"
      },
      "partnerPayments": {
        "label": "Zahlungen an Partner"
      },
      "verification": {
        "label": "Verifizierung"
      },
      "walletManagement": {
        "label": "Wallet"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Verfügbare Anbieter",
    "dismiss": "Verwerfen",
    "navigate": "Navigieren",
    "noResult": "Kein Ergebnis gefunden",
    "recentUsed": "Vor Kurzem verwendet",
    "resultsLabel": "Anbieter gefunden",
    "searchError": "Da ist etwas schiefgelaufen.",
    "searchErrorMessage": "Wir arbeiten daran, das Problem zu beheben. Bitte versuche es in Kürze erneut.",
    "searchForVendor": "Nach Anbieter suchen",
    "select": "Auswählen",
    "storeResultsLabel": "Verfügbare Anbieter",
    "supplierAlreadyInUseError": "Du verwendest derzeit dieses Anbieterkonto",
    "switchVendor": "Auf Anbieter umstellen",
    "wishmorning": "Guten Morgen"
  }
}