import { computed, ref } from 'vue';
import { IUseToasts, ToastMessage } from '@/components/Toasts/IUseToasts';

const toastQueue = ref<ToastMessage[]>([]);

export default function useToasts(): IUseToasts {
    // TODO refactor this to take the toast as argument
    const removeToast = (toast: ToastMessage) => {
        toastQueue.value = toastQueue.value.filter((t) => {
            if (t.message !== toast.message) {
                return true;
            }
            return t.intent !== toast.intent;
        });
    };

    const addToast = (toast: ToastMessage) => {
        if (toastQueue.value.filter((t) => t.message === toast.message && t.intent === toast.intent).length > 0) {
            return;
        }
        toastQueue.value.push(toast);
        setTimeout(() => {
            removeToast(toast);
        }, 5000);
    };

    const hasToasts = computed(() => toastQueue.value.length > 0);

    return {
        addToast,
        removeToast,
        hasToasts,
        // TODO refactor this to export it as computed (readonly)
        toastQueue,
    };
}
