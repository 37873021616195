<template>
  <div class="toast-layer" :class="{'inMicroFrontend':isMicroFrontend, 'isMobile': isMobile}">
    <c-toast
      v-for="(toastMessage, index) in uniqueToastMessages"
      :key="index"
      :intent="toastMessage.intent"
      @close="() => $emit('close-toast', index)"
      :message="toastMessage.message"
      class="c-layer-overlay"
      :action-button-label="toastMessage.actionLabel"
      @handle-action="toastMessage.handleAction"
    />
  </div>
</template>

<script setup lang="ts">
import { CToast } from '@bookingkit/coppi';
import { defineProps, defineEmits } from 'vue';

type ToastMessage = {intent: string, message: string, actionLabel: string, handleAction:() => void};
const props = defineProps<{
    toastMessages:ToastMessage[],
    isMicroFrontend: boolean,
    isMobile: boolean,
}>();

const uniqueToastMessages = props.toastMessages.reduce((acc, current) => {
    const x = acc.find((item) => item.message === current.message);
    if (!x) {
        return acc.concat([current]);
    }
    return acc;
}, [] as ToastMessage[]);

const $emit = defineEmits(['close-toast']);

</script>

<style scoped>
.toast-layer {
  position: fixed;
  top: var(--s15);
  right: calc(50%);
  transform: translateX(+50%);
}

.toast-layer.inMicroFrontend,
.toast-layer.isMobile {
  right: 50%;
}

.toast-layer.isMobile {
  bottom: var(--mobile-menu-height);
}

.c-toast-container {
  margin-bottom: var(--s3);
  position: relative;
}
</style>
